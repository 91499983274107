import fromExponential from "from-exponential";
import fp from "evm-fp";
import { BigNumber } from "ethers";

export function bn(x, precision) {
    let xs

    if (typeof x === 'number') {
        xs = x.toString()
    } else {
        xs = x
    }
    if (xs[0] === '.') {
        xs = '0' + xs
    }

    if (xs.includes("e")) {
        xs = fromExponential(x);
    }
    if (precision) {
        return fp(xs, precision)
    }
    return BigNumber.from(xs);
}
