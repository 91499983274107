import { createContext, ReactNode, Reducer, useContext, useReducer } from "react"
import { BigNumber } from '@ethersproject/bignumber'
import { deepmergeCustom } from 'deepmerge-ts'
import React from "react"

interface ChainState {
    [chainId: number]: {
        blockNumber: number
        addresses: {
            [address: string]: {
                balance?: BalanceState
                callValues?: {
                    [functionSignature: string]: {
                        [argsHash: string]: CallValueState
                    }
                }
            }
        }
    }
}

export interface CallValueState<T = any> {
    value?: T
    isUpdating?: boolean
    error?: Error
    mostRecentError?: Error
    lastUpdatedBlock?: number
}

export type BalanceState = CallValueState<BigNumber>

enum ChainStateActionName {
    BlockNumberUpdated = 'BlockNumberUpdated',
    CallValueUpdating = 'CallValueUpdating',
    CallValueUpdated = 'CallValueUpdated',
    CallValueUpdateFailed = 'CallValueUpdateFailed',
    BalanceUpdating = 'BalanceUpdating',
    BalanceUpdated = 'BalanceUpdated',
    BalanceUpdateFailed = 'BalanceUpdateFailed',
}

interface Action {
    type: string
    payload: unknown
}

interface BlockNumberUpdatedAction extends Action {
    type: ChainStateActionName.BlockNumberUpdated
    payload: {
        chainId: number
        blockNumber: number
    }
}

interface CallValueUpdatingAction extends Action {
    type: ChainStateActionName.CallValueUpdating
    payload: {
        chainId: number
        address: string
        functionSignature: string
        argsHash: string
    }
}

interface CallValueUpdatedAction extends Action {
    type: ChainStateActionName.CallValueUpdated
    payload: {
        chainId: number
        address: string
        functionSignature: string
        argsHash: string
        value: any
        blockNumber: number
    }
}

interface CallValueUpdateFailedAction extends Action {
    type: ChainStateActionName.CallValueUpdateFailed
    payload: {
        chainId: number
        address: string
        functionSignature: string
        argsHash: string
        error: Error
        blockNumber: number
    }
}

interface BalanceUpdatingAction extends Action {
    type: ChainStateActionName.BalanceUpdating
    payload: {
        chainId: number
        address: string
    }
}

interface BalanceUpdatedAction extends Action {
    type: ChainStateActionName.BalanceUpdated
    payload: {
        chainId: number
        address: string
        value: BigNumber
        blockNumber: number
    }
}

interface BalanceUpdateFailedAction extends Action {
    type: ChainStateActionName.BalanceUpdateFailed
    payload: {
        chainId: number
        address: string
        error: Error
        blockNumber: number
    }
}

type ChainStateAction =
    | BlockNumberUpdatedAction
    | CallValueUpdatingAction
    | CallValueUpdatedAction
    | CallValueUpdateFailedAction
    | BalanceUpdatingAction
    | BalanceUpdatedAction
    | BalanceUpdateFailedAction

// Always overwrite arrays
const deepMerge = deepmergeCustom({ mergeArrays: false })

function chainStateReducer(state: ChainState, action: ChainStateAction): ChainState {
    switch (action.type) {
        case ChainStateActionName.BlockNumberUpdated:
            return blockNumberUpdated(state, action.payload)
        case ChainStateActionName.CallValueUpdating:
            return callValueUpdating(state, action.payload)
        case ChainStateActionName.CallValueUpdated:
            return callValueUpdated(state, action.payload)
        case ChainStateActionName.CallValueUpdateFailed:
            return callValueUpdateFailed(state, action.payload)
        case ChainStateActionName.BalanceUpdating:
            return balanceUpdating(state, action.payload)
        case ChainStateActionName.BalanceUpdated:
            return balanceUpdated(state, action.payload)
        case ChainStateActionName.BalanceUpdateFailed:
            return balanceUpdateFailed(state, action.payload)
        default:
            // @ts-ignore
            throw new Error(`Action type '${action.type} not supported.`);
    }
}

function blockNumberUpdated(state: ChainState, payload: BlockNumberUpdatedAction['payload']) {
    const { chainId, blockNumber } = payload
    return deepMerge(state, {
        [chainId]: {
            blockNumber
        }
    }) as ChainState
}

function callValueUpdating(state: ChainState, payload: CallValueUpdatingAction['payload']) {
    const { chainId, address, functionSignature, argsHash } = payload
    return deepMerge(state, {
        [chainId]: {
            addresses: {
                [address]: {
                    callValues:{
                        [functionSignature]: {
                            [argsHash]: {
                                isUpdating: true,
                            }
                        }
                    }
                }
            }
        }
    }) as ChainState
}

function callValueUpdated(state: ChainState, payload: CallValueUpdatedAction['payload']) {
    const { chainId, address, functionSignature, argsHash, value, blockNumber } = payload
    return deepMerge(state, {
        [chainId]: {
            addresses: {
                [address]: {
                    callValues:{
                        [functionSignature]: {
                            [argsHash]: {
                                value,
                                isUpdating: false,
                                error: undefined,
                                lastUpdatedBlock: blockNumber,
                            }
                        }
                    }
                }
            }
        }
    }) as ChainState
}

function callValueUpdateFailed(state: ChainState, payload: CallValueUpdateFailedAction['payload']) {
    const { chainId, address, functionSignature, argsHash, error } = payload
    return deepMerge(state, {
        [chainId]: {
            addresses: {
                [address]: {
                    callValues:{
                        [functionSignature]: {
                            [argsHash]: {
                                isUpdating: false,
                                error,
                                mostRecentError: error
                            }
                        }
                    }
                }
            }
        }
    }) as ChainState
}

function balanceUpdating(state: ChainState, payload: BalanceUpdatingAction['payload']) {
    const { chainId, address } = payload
    return deepMerge(state, {
        [chainId]: {
            addresses: {
                [address]: {
                    balance: {
                        isUpdating: true,
                    }
                }
            }
        }
    }) as ChainState
}

function balanceUpdated(state: ChainState, payload: BalanceUpdatedAction['payload']) {
    const { chainId, address, value, blockNumber } = payload
    return deepMerge(state, {
        [chainId]: {
            addresses: {
                [address]: {
                    balance: {
                        value,
                        isUpdating: false,
                        error: undefined,
                        lastUpdatedBlock: blockNumber,
                    }
                }
            }
        }
    }) as ChainState
}

function balanceUpdateFailed(state: ChainState, payload: BalanceUpdateFailedAction['payload']) {
    const { chainId, address, error, blockNumber } = payload
    return deepMerge(state, {
        [chainId]: {
            addresses: {
                [address]: {
                    balance: {
                        isUpdating: false,
                        error,
                        mostRecentError: error,
                        lastUpdatedBlock: blockNumber,
                    }
                }
            }
        }
    }) as ChainState
}

export const ChainStateContext = createContext([{} as any, (action: any) => {}])

interface ChainStateProviderProps {
    children: ReactNode
}

export function ChainStateProvider({ children }: ChainStateProviderProps) {
    const [store, dispatch] = useReducer<Reducer<ChainState, ChainStateAction>>(chainStateReducer, {})

    return (
        <ChainStateContext.Provider value={[store, dispatch]}>
            {children}
        </ChainStateContext.Provider>
    )
}

export function useChainStateReducer() {
    return useContext(ChainStateContext) as [ChainState, React.Dispatch<any>]
}
