import styled from "styled-components";
import PoolCard from "./PoolCard";
import {useDisplayedPools} from '../../../hooks/useDisplayedPools';


function Pools() {
    const pools = useDisplayedPools()
    const cards = pools
        .map((pool) => {
            return <PoolCard key={`pool-${pool.id}`} poolInfo={pool} />
        }
    )

    return <Div>
        {cards}
    </Div>
}

export default Pools

const Div = styled.div`
  text-align: left;
  width: 100%;
  padding: 0 10px;
`
