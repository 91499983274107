import styled, {css} from "styled-components";
import { utils } from 'ethers'
import {useEffect, useState} from "react";
import { bn } from "../../../../../utils/bn";
import {isNumeric} from "../../../../../utils/isNumeric";
import {trimDecimalStringToPrecision} from "../../../../../utils/trimDecimalStringToPrecision";
import {LoadingSpinner} from "../../../shared/LoadingSpinner";

const { formatUnits } = utils

export function AmountForm({
    titleText,
    submitText,
    tokenSymbol,
    tokenPrecision,
    amountAvailable,
    onSubmit,
    loadingText,
    errorContent,
    clearError,
    isCardOpen,
}) {
    const [inputAmount, setInputAmount] = useState('')
    const [bnInputAmount, setBnInputAmount] = useState(null)
    const [showNotEnoughError, setShowNotEnoughError] = useState(false)

    if (!onSubmit) {
        onSubmit = () => {}; // Make sure this is set so app doesn't break
    }

    function truncateAmount(valueString) {
        if (valueString.indexOf('.') === -1) return valueString
        const [wholeNumber, mantissa] = valueString.split('.')
        const truncatedMantissa = mantissa.substring(0, 4)
        if (truncatedMantissa === '0000') {
            if (wholeNumber === '0') return '< 0.0001'
            return wholeNumber
        }
        return [wholeNumber, <Point>.</Point>, truncatedMantissa]
    }

    function clearInput() {
        setInputAmount('')
        setBnInputAmount(null)
    }

    function onSubmitWrapper() {
        onSubmit({
            amount: bnInputAmount,
            clearInput,
        })
    }

    function onInputChange(event) {
        let { value } = event.target

        // More than one zero in a row as the while input should be treated as a single zero
        if (value !== '' && [...value].every((c) => c === '0')) {
            value = '0'
        }

        // Leading zero must be followed by a decimal
        if (value.length >= 2 && value[0] === '0' && value[1] !== '.') {
            value = value.split('0').slice(-1)[0]
        }

        // Ignore decimal places more than precision
        if (value.indexOf('.') !== -1 && value.split('.')[1].length > tokenPrecision) {
            value = trimDecimalStringToPrecision(value, tokenPrecision)
        }

        // No negative numbers
        if (value[0] === '-') return

        // No scientific notation
        if (value.indexOf('e') !== -1) return

        // Allow user to start writing a decimal without a leading zero, but disable submit
        if (value === '.') {
            setInputAmount(value)
            setBnInputAmount(null)
            return
        }

        clearError()
        setShowNotEnoughError(false)

        // Allow user to 1. clear the input and 2. have zero, while submit is disabled
        if (value === '' || parseFloat(value) === 0) {
            setInputAmount(value)
            setBnInputAmount(null)
            return
        }

        // Must be numeric (final sanity check)
        if (!isNumeric(value)) return


        setInputAmount(value.trim())
        let inputAmountBn
        try {
            const trimmedDecimalValue = value[value.length - 1] === '.' ? value.slice(0, value.length - 1) : value
            inputAmountBn = bn(trimmedDecimalValue, tokenPrecision)
        } catch (e) {
            console.error(e)
            setBnInputAmount(null)
            return
        }
        setBnInputAmount(inputAmountBn)
        const hasEnoughBalance = inputAmountBn.lte(amountAvailable)
        setShowNotEnoughError(!hasEnoughBalance)
    }

    function getSubmitButtonState() {
        if (
            showNotEnoughError ||
            bnInputAmount === null ||
            loadingText
        ) {
            return { 'disabled': true, 'loading': loadingText ? true : undefined }
        }

        return {}
    }

    function setAmountFromPercentage(percentage) {
        if (percentage === 0) {
            clearInput()
            return
        }
        let amountBn
        if (percentage === 0.75) { // TODO: Find a better way to avoid precision errors
            amountBn = amountAvailable.mul(bn(1, tokenPrecision)).div(bn(4, tokenPrecision)).mul(bn(3))
        } else {
            const inversePercentageBn = bn((1/percentage), tokenPrecision)
            amountBn = amountAvailable.mul(bn(1, tokenPrecision)).div(inversePercentageBn)
        }
        if (amountBn.toString() === '0') {
            clearInput()
        } else {
            setInputAmount(formatUnits(amountBn, tokenPrecision))
            setBnInputAmount(amountBn)
            setShowNotEnoughError(false)
        }
    }

    function getErrorElement() {
        if (showNotEnoughError) {
            return <ErrorMessage show={true}>Insufficient balance.</ErrorMessage>
        }
        if (errorContent) {
            return <ErrorMessage show={true}>{errorContent}</ErrorMessage>
        }
        return <ErrorMessage show={false}>&nbsp;</ErrorMessage>
    }

    function getInnerButtonContent() {
        if (loadingText) {
            return <ButtonContent>
                <LoadingSpinner />
                {loadingText}
            </ButtonContent>
        }
        return <ButtonContent>{submitText}</ButtonContent>
    }

    useEffect(() => {
        if (!isCardOpen) {
            clearInput()
            clearError()
            setShowNotEnoughError(false)
        }
    }, [isCardOpen])

    return <AmountFormDiv>
        <Title>{titleText}</Title>
        <Available onClick={setAmountFromPercentage.bind({}, 1)}>
            {truncateAmount(formatUnits(amountAvailable, tokenPrecision))} {tokenSymbol} Available
        </Available>
        <AmountInput value={inputAmount} onChange={onInputChange} placeholder={'0.0'} />
        <PercentageSelector>
            <div onClick={setAmountFromPercentage.bind({}, 0.0) }>0%</div>
            <div onClick={setAmountFromPercentage.bind({}, 0.25)}>25%</div>
            <div onClick={setAmountFromPercentage.bind({}, 0.50)}>50%</div>
            <div onClick={setAmountFromPercentage.bind({}, 0.75)}>75%</div>
            <div onClick={setAmountFromPercentage.bind({}, 1)   }>100%</div>
        </PercentageSelector>
        {getErrorElement()}
        <SubmitButton
            {...getSubmitButtonState()}
            onClick={onSubmitWrapper}
        >
            {getInnerButtonContent()}
        </SubmitButton>
    </AmountFormDiv>
}

const AmountFormDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: start;
  width: 300px;
  //height: 270px;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 17px;
`

const Available = styled.span`
  font-size: 14px;
  margin-bottom: 8px;
  color: #545463;
  cursor: pointer;
`

const AmountInput = styled.input`
  background-color: #ffffff;
  border: 1px solid rgba(15, 22, 33, 0.2);
  border-radius: 8px;
  width: 240px;
  height: 40px;
  padding: 10px 12px;
  font-size: 18px;

  ::placeholder {
    color: rgba(15, 22, 33, 0.48);
  }
`

const PercentageSelector = styled.div`
  display: flex;
  width: 240px;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  margin: 6px 0 15px;
  color: #545463;
  
  div {
    cursor: pointer;
  }
`

const ErrorMessage = styled.div`
  visibility: ${({ show }) => show ? 'visible' : 'hidden'};
  margin: 0 0 6px;
  font-size: 14px;
  font-weight: 600;
  color: #ad2a1c;
`

const SubmitButton = styled.button`
  width: 240px;
  height: 40px;
  font-weight: 600;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: not-allowed;
  
  ${({ disabled }) => {
    if (!disabled) {
      return css`
        cursor: pointer;
        color: white;
        &:hover ::before {
          opacity: 1;
        }
        &:active {
          transform: scale(0.97);
        }
      `
    }
  }}

  ${({ loading }) => {
    if (loading) {
      return css`
            color: white !important;
          `
    }
  }}

  border-radius: 8px;
  background: linear-gradient(to right, #c44536, #c48c36);
  position: relative;
  z-index: 1;
  transition: transform 0.05s;

  ::before {
    border-radius: 8px;
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to left, #c44536, #c48c36);;
    z-index: -1;
    transition: opacity 0.15s linear;
    opacity: 0;
  }
`

const Point = styled.span`
    margin-left: 1px;
    margin-right: 1px;
`

const ButtonContent = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    margin-right: 10px;
  }
`