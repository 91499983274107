import styled, {css, keyframes} from "styled-components";
import DownArrow from "../../../images/downarrow.svg";
import { Image } from "../shared/Image";
import { useEffect, useLayoutEffect, useRef, useState} from "react";
import {PoolCardFullContent} from "./PoolCardFullContent";
import {trimDecimalStringToPrecision} from "../../../utils/trimDecimalStringToPrecision";
import {useSmoothScrollTo} from "../../../utils/useSmoothScrollTo";
import {formatUnits} from "ethers/lib.esm/utils";
import {useWindowSize} from "../../../utils/useWindowSize";
import {bn} from "../../../utils/bn";
import {AppStateActionName, useAppStateReducer} from '../../../AppState';
import {useSfiPrice} from '../../../hooks/useSfiPrice';
import {useWeb3ReactPlus} from '../../../Web3ReactPlus/Web3ReactPlusProvider';
import {usePendingSfi} from '../../../hooks/usePendingSfi';
import {useAmountStaked} from '../../../hooks/useAmountStaked';
import {useTokenIcons} from '../../../hooks/useTokenIcons';
import {useIsCardOpen} from '../../../hooks/useIsCardOpen';
import {formatNumber} from '../../../utils/formatNumber';
import {useBlockNumber} from '../../../Web3ReactPlus/hooks/useBlockNumber';
import {useSaffronStakingProtocolInfo} from '../../../hooks/useSaffronStakingProtocolInfo';


const CLOSED_CARD_HEIGHT = 69

function PoolCard({ poolInfo }) {
  const sfiPrice = useSfiPrice()
  const [_, dispatch] = useAppStateReducer()
  const { chainId } = useWeb3ReactPlus()

  const [cardScrollHeight, setCardScrollHeight] = useState(CLOSED_CARD_HEIGHT)
  const cardRef = useSmoothScrollTo(`#${poolInfo.id}`, `scrollToPool${poolInfo.id}`)
  const cardContentRef = useRef()
  const [pendingUsd, setPendingUsd] = useState(null)
  const windowSize = useWindowSize()

  const [token1Svg, token2Svg] = useTokenIcons(poolInfo)
  const isCardOpen = useIsCardOpen(poolInfo.id)

  const { rewardCutoffBlockNumber, sfiPerBlockWei } = useSaffronStakingProtocolInfo()
  const blockNumber = useBlockNumber()

  const [pendingSfi] = usePendingSfi(poolInfo.id)
  const [amountStaked] = useAmountStaked(poolInfo.id)
  const isEmittingRewards = blockNumber <= rewardCutoffBlockNumber && parseFloat(sfiPerBlockWei) > 0

  // Get scroll height of div (including hidden content) so we can reveal the right amount
  // Update on resize to check if things reshuffled upon responding
  useLayoutEffect(() => {
    setCardScrollHeight(CLOSED_CARD_HEIGHT + cardContentRef.current.scrollHeight)
  }, [windowSize, cardContentRef.current])

  useEffect(() => {
    if (!pendingSfi || pendingSfi.toString() === '0') {
      if (!amountStaked || amountStaked.toString() === '0') {
        setPendingUsd(null); return
      }
      setPendingUsd(isEmittingRewards ? '0 USD' : null); return
    }
    const valueUsd = pendingSfi.mul(bn(sfiPrice, 18)).div(bn(1, 18))
    let pendingRewards = trimDecimalStringToPrecision(formatUnits(valueUsd, 18), 4) + ' USD'
    if (pendingRewards === '0 USD') {
      pendingRewards = '<0.0001 USD'
    }
    setPendingUsd(pendingRewards)
  }, [pendingSfi])

  function toggleOpen() {
    dispatch({
      type: AppStateActionName.TogglePoolCard,
      payload: { chainId, poolId: poolInfo.id }
    })
  }

  const buttonText = isCardOpen ? 'Close' : 'Deposit/Withdraw'

  return (
    <Card open={isCardOpen} scrollHeight={cardScrollHeight} ref={cardRef}>
      <CardTop onClick={toggleOpen}>
        <IconColumn>
          <IconContainer>
            <Icon>
              <Image width="24px" height="24px" src={token1Svg} />
            </Icon>
            {poolInfo.stakedToken?.underlyingToken1 ?
              <Icon overlap={true}>
                <Image width="24px" height="24px" src={token2Svg}/>
              </Icon>
            : null}
          </IconContainer>
        </IconColumn>
        <TitleColumn>
          <TitleContainer>
            <ColumnValue>{poolInfo.pairName}</ColumnValue>
            <ColumnTitle>{poolInfo.emoji} {poolInfo.platform}</ColumnTitle>
          </TitleContainer>
        </TitleColumn>
        <EarningsColumn hide={!pendingUsd}>
          <TitleContainer>
            <ColumnTitle>Earnings</ColumnTitle>
            <GradientColumnValue>{pendingUsd}</GradientColumnValue>
          </TitleContainer>
        </EarningsColumn>
        <InfoColumn>
          <TitleContainer>
            <ColumnTitle>APR</ColumnTitle>
            <ColumnValue>{trimDecimalStringToPrecision(formatNumber(poolInfo.apr), 2, 5)}%</ColumnValue>
          </TitleContainer>
        </InfoColumn>
        <InfoColumn>
          <TitleContainer>
            <ColumnTitle>TVL</ColumnTitle>
            <ColumnValue>${trimDecimalStringToPrecision(formatNumber(poolInfo.tvlUsd), 0)}</ColumnValue>
          </TitleContainer>
        </InfoColumn>
        <DepositColumn open={isCardOpen}>
          <Button className={'dropdown-button'}>{buttonText}</Button>
          <Image src={DownArrow} />
        </DepositColumn>
      </CardTop>
      <PoolCardFullContent poolInfo={poolInfo} innerRef={cardContentRef}/>
    </Card>
  );
}

export default PoolCard;


const Card = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  ${({ open, scrollHeight }) => {
    if (!open) {
      return css`
        height: ${CLOSED_CARD_HEIGHT}px
      `
    } else {
      return css`
        height: ${scrollHeight}px;
      `
    }
  }};
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;
  border-radius: 8px;
  background-color: white;
  margin: 0 auto 12px;
  overflow: hidden;
`;

const IconContainer = styled.div`
  width: 50px;
  margin-top: 5px;
  display: inline-flex;
`

const Icon = styled.div`
  margin-left: ${(props) => props.overlap ? `-5px` : '0'};
`;

const CardTop = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 15px;
  width: 100%;
  height: ${CLOSED_CARD_HEIGHT}px;
  &:hover .dropdown-button::before {
    opacity: 1;
  }
`;
const TitleContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
`;

const IconColumn = styled.div`
  width: 50px;
  display: inline-flex;
  text-align: left;
  align-items: center;
`;

const TitleColumn = styled.div`
  width: 17%;
  display: inline-flex;
  text-align: left;
  align-items: center;
  margin-right: auto;
  white-space: nowrap;
`;

const InfoColumn = styled.div`
  width: 13%;
  @media (max-width: 800px) {
    display: none;
  }
`;

const EarningsColumn = styled.div`
  ${({ hide }) => {
    return hide ? css`
      visibility: hidden;
    `: ''
  }}
  width: 13%;
  @media (max-width: 800px) {
    width: 30%
  }
`;

const DepositColumn = styled.div`
  display: inline-flex;
  margin-left: auto;
  font-weight: 600;
  
  @media (max-width: 800px) {
    button {
      display: none;
    }
  }
  
  img {
    transition: 0.1s linear;
    ${({ open }) => {
      if (open) { return css`
        transform: rotate(-180deg);
      `} else { return css`
        transform: rotate(0);
      `}
    }
  }
`;

// Texts

const ColumnTitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: #545463;
  text-align: left;
`;

const ColumnValue = styled.span`
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  color: #0f1621;
`;

const CycleSfiColors = keyframes`
  0% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
`

const GradientColumnValue = styled.span`
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  background-image: linear-gradient(to right, #c44536, #c44536, #c48c36, #c44536, #c44536);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;

  background-size: 400%;
  background-position: right;

  animation-name: ${CycleSfiColors};
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

// Icons

const Icondiv = styled.div`
  margin-left: -5px;
  z-index: 99;
  ${(props) =>
    props.remove &&
    css`
      opacity: 0;
    `}
`;

// Button

const Button = styled.button`
  font-size: 15px;
  width: 162px;
  height: 32px;
  border: none;
  border-radius: 100px;
  background-image: linear-gradient(to right, #c44536, #c48c36);
  color: #ffffff;
  font-weight: 600;
  margin-right: 15px;
  cursor: pointer;
  position: relative;
  z-index: 1;

  ::before {
    border-radius: 100px;
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to left, #c44536, #c48c36);;
    z-index: -1;
    transition: opacity 0.15s linear;
    opacity: 0;
  }
`;
