import { useChainStateReducer } from "../ChainStateProvider";
import { useWeb3ReactPlus } from "../Web3ReactPlusProvider";


export function useBlockNumber(): number {
    const [chainState] = useChainStateReducer()
    const { chainId } = useWeb3ReactPlus()

    return chainState?.[chainId ?? 0]?.blockNumber || 0
}
