import styled, {keyframes} from "styled-components";
import Navbar from "./components/ui/Navbar";
import './App.css'
import SearchAndFilter from "./components/ui/pools/SearchAndFilter";
import FeaturedPools from "./components/ui/featured-pools/FeaturedPools";
import Pools from "./components/ui/pools/Pools";
import {useWeb3ReactPlus} from "./Web3ReactPlus/Web3ReactPlusProvider";
import {useApiData} from "./hooks/useApiData";
import {useRawPools} from './hooks/useRawPools';
import {UnsupportedChain} from './components/ui/UnsupportedChain';
import {ConnectWalletModal} from './components/ui/modals/ConnectWallet';
import ellipsisLoader from './images/ellipsisLoader.svg'
import {useState} from 'react';


export function App() {
  const { triedAutoconnectInjected } = useWeb3ReactPlus()
  const attemptedLoad = useApiData()



  const noPools = !(useRawPools().length)


  return (
    <Section>
      <Navbar />
      <PoolsBodyOuter>
        { triedAutoconnectInjected && attemptedLoad ?
          noPools ? <UnsupportedChain />
          : <PoolsBodyInner>
            <FeaturedPools />
            <SearchAndFilter />
            <Pools />
          </PoolsBodyInner>
        : <Loader><img alt={'Loading'} src={ellipsisLoader} /></Loader> }
      </PoolsBodyOuter>
    </Section>
  )
}

const Loader = styled.div`
  width: 100px;
  margin: 100px auto 0;
  text-align: center;
  
  img {
    width: 100%;
  }
`

const Section = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PoolsBodyOuter = styled.div`
  width: 100%;
`

const fadeInOnLoad = keyframes`
  0% {
    opacity: 0
  }
  100% {
    opacity: 1;
  }
`

const PoolsBodyInner = styled.div`
  animation: ${fadeInOnLoad} 0.15s ease-out;
`
