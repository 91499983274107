import {trimExcessZeros} from "./trimExcessZeros";

export function trimDecimalStringToPrecision(valueString, precision, maxWholeDigits = 12, scientificPrecision = precision) {
    if (valueString.indexOf('.') === -1) return valueString
    if (valueString.indexOf('e') > -1) {
        return formatScientificNotation(valueString, scientificPrecision)
    }
    const splitValue = valueString.split('.')
    if (splitValue.length !== 2) throw Error(`'${valueString}' is not a valid decimal string.`)
    const [left, right] = splitValue
    const rightTrimmed = right.substring(0, precision)
    if (left.length > maxWholeDigits) {
        const eFormatted = `${left[0]}.${left.substring(1)}${right}e+${left.length-1}`
        return formatScientificNotation(eFormatted, scientificPrecision)
    }

    return trimExcessZeros(`${left}.${rightTrimmed}`)
}

function formatScientificNotation(valueString, precision) {
    let exponent = valueString.split('e')[1]
    if (exponent[0] === "+") {
        exponent = exponent.substring(1)
    }
    const trimmedValue = valueString.substring(0, precision + 2)
    return [`${trimmedValue}⨉10`, <sup>{exponent}</sup>]
}