import { UseContractCallValueReturnValue, useContractCallValue } from "./useContractCallValue";
import ERC20 from '../artifacts/ERC20.json'
import { BigNumber } from "@ethersproject/bignumber";

export function useErc20Balance(
  tokenAddress: string,
  balanceAddress: string,
  autoUpdate = true,
  updateEveryNBlocks = 1,
): UseContractCallValueReturnValue<BigNumber> {
    return useContractCallValue({
        address: tokenAddress,
        functionName: 'balanceOf',
        artifact: ERC20,
        args: [balanceAddress],
        autoUpdate,
        updateEveryNBlocks,
    })
}
