export function trimExcessZeros(str) {
    let start = 0;
    let end = str.length;

    // while(start < end && str[start] === '0')
    //     ++start;

    while(end > start && str[end - 1] === '0')
        --end;

    let result = (start > 0 || end < str.length) ? str.substring(start, end) : str;

    if (result[result.length - 1] === '.') {
        result = result.substring(0, result.length - 1)
    }

    return result.length ? result : '0'
}