import styled, {keyframes} from "styled-components";

const SCALE = 0.35

function scale(number) {
    return Math.ceil(number * SCALE)
}

const spinOutside = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`

const spinInside = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`

const _LoadingSpinner = styled.div`
      display: inline-block;
      position: relative;
      width: ${scale(80)}px;
      height: ${scale(80)}px;
  animation: ${spinOutside} 3.8832s linear infinite;
     div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: ${scale(64)}px;
      height: ${scale(64)}px;
      margin: ${scale(8)}px;
      border: ${scale(8)}px solid #fff;
      border-radius: 50%;
      animation: ${spinInside} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
       
      border-color: #fff transparent transparent transparent;
    }

  
    div:nth-child(1) {
      animation-delay: -0.45s;
    }
  div:nth-child(2) {
      animation-delay: -0.3s;
    }
  div:nth-child(3) {
      animation-delay: -0.15s;
    }
`

export function LoadingSpinner() {
    return <_LoadingSpinner><div></div><div></div><div></div><div></div></_LoadingSpinner>
}