import { BigNumber } from "@ethersproject/bignumber";
import { UseContractCallValueReturnValue, useContractCallValue } from "../Web3ReactPlus/hooks/useContractCallValue";
import { useSaffronStakingProtocolInfo } from "./useSaffronStakingProtocolInfo";
import SaffronStakingV2 from '../artifacts/contracts/SaffronStakingV2.sol/SaffronStakingV2.json'
import { useWeb3ReactPlus } from "../Web3ReactPlus/Web3ReactPlusProvider";


export function usePendingSfi(poolId: number): UseContractCallValueReturnValue<BigNumber> {
  const { activeAddress } = useWeb3ReactPlus()
  const { saffronStakingAddress } = useSaffronStakingProtocolInfo()
  return useContractCallValue({
    address: saffronStakingAddress ?? '',
    artifact: SaffronStakingV2,
    functionName: 'pendingSFI',
    args: [poolId, activeAddress],
  })
}
