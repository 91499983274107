import styled, { keyframes } from "styled-components";
import { useWeb3ReactPlus } from "../../Web3ReactPlus/Web3ReactPlusProvider";

export function UnsupportedChain() {
  const { provider } = useWeb3ReactPlus()

  function switchToChain() {
    if (!provider) return
    provider.send('wallet_switchEthereumChain', [{ chainId: '0x4' }])
  }

  return <Wrap>
    <p>Looks like you're on an unsupported chain.</p>
    <p><a href={'#'} onClick={switchToChain}>Click here to switch to the Rinkeby testnet and continue previewing the new app.</a></p>
  </Wrap>
}

const fadeInOnLoad = keyframes`
  0% {
    opacity: 0
  }
  100% {
    opacity: 1;
  }
`

const Wrap = styled.div`
  margin-top: 70px;
  text-align: center;
  animation: ${fadeInOnLoad} 0.15s ease-out;;

`
