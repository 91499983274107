import styled from "styled-components";
import FeaturedPool from "./FeaturedPool";
import {useRawPools} from "../../../hooks/useRawPools";

function FeaturedPools() {
    const rawPools = useRawPools()

    const cards = rawPools
        .filter((pool) => pool.isFeatured)
        .slice(0, 3)
        .map((pool) => {
            return <FeaturedPool key={`featured-pool-${pool.id}`} poolInfo={pool} />
        }
    )

    return <Div>
        {cards}
    </Div>
}

export default FeaturedPools

const Div = styled.div`
    display: flex;
    justify-content: center;
    padding: 10px;
    width: 100%;

    @media only screen and (max-width: 800px) {
      display: none;
    }
`
