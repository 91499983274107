import React, { createContext, ReactNode, Reducer, useContext, useReducer } from "react";
import { deepmergeCustom } from 'deepmerge-ts'

const merge = deepmergeCustom({ mergeArrays: false })

// Types
type DeepPartial<T> = {
    [P in keyof T]?: DeepPartial<T[P]>;
};

interface ApiData<T = any> {
    data: T
    isFetching: boolean
    error?: Error
}

interface AppState {
    [chainId: number]: {
        api: {
            pools: ApiData
            icons: ApiData
            'protocol-info': ApiData
        }
        openPoolCard?: number
        displayedPools?: any
    }
}

export type ApiPath = keyof AppState[number]['api']

export enum AppStateActionName {
    FetchingApiData = 'FetchingApiData',
    UpdatePoolsData = 'UpdatePoolsData',
    TogglePoolCard = 'TogglePoolCard',
    DisplayPools = 'DisplayPools',
    LoadIconData = 'LoadIconData',
    LoadProtocolInfo = 'LoadProtocolInfo',
}

interface Action {
    type: string
    payload: unknown
}

interface FetchingApiDataAction extends Action {
    type: AppStateActionName.FetchingApiData
    payload: { chainId: number, path: ApiPath }
}

interface UpdatePoolsDataAction extends Action {
    type: AppStateActionName.UpdatePoolsData
    payload: {
        chainId: number
        data: any
        isFetching: boolean
        error?: Error
    }
}

interface LoadIconDataAction extends Action {
    type: AppStateActionName.LoadIconData
    payload: {
        chainId: number
        data: any
        isFetching: boolean
        error?: Error
    }
}

interface LoadProtocolInfoAction extends Action {
    type: AppStateActionName.LoadProtocolInfo
    payload: {
        chainId: number
        data: any
        isFetching: boolean
        error?: Error
    }
}

interface TogglePoolCardAction extends Action {
    type: AppStateActionName.TogglePoolCard
    payload: {
        chainId: number
        poolId: number
    }
}

interface DisplayPoolsAction extends Action {
    type: AppStateActionName.DisplayPools
    payload: {
        chainId: number
        pools: any[]
    }
}

type AppStateAction =
    | FetchingApiDataAction
    | UpdatePoolsDataAction
    | TogglePoolCardAction
    | DisplayPoolsAction
    | LoadIconDataAction
    | LoadProtocolInfoAction

// Reducer action router
function appStateReducer(state: AppState, action: AppStateAction): AppState {
    switch (action.type) {
        case AppStateActionName.FetchingApiData:
            return fetchingApiData(state, action.payload)
        case AppStateActionName.UpdatePoolsData:
            return updatePoolsData(state, action.payload)
        case AppStateActionName.TogglePoolCard:
            return togglePoolCard(state, action.payload)
        case AppStateActionName.DisplayPools:
            return displayPools(state, action.payload)
        case AppStateActionName.LoadIconData:
            return loadIconData(state, action.payload)
        case AppStateActionName.LoadProtocolInfo:
            return loadProtocolInfo(state, action.payload)
        default:
            // @ts-ignore
            throw new Error(`Action type '${action.type} not supported.`);
    }
}

// Reducers
function fetchingApiData(state: AppState, payload: FetchingApiDataAction['payload']): AppState {
    const { chainId, path } = payload
    return merge(state, {
        [chainId]: {
            api: {
                [path]: {
                    isFetching: true
                }
            }
        }
    }) as AppState
}

function loadIconData(state: AppState, payload: LoadIconDataAction['payload']): AppState {
    const { chainId, data, isFetching, error } = payload
    return merge(state, {
        [chainId]: {
            api: {
                icons: { data, isFetching, error }
            }
        }
    }) as AppState
}

function loadProtocolInfo(state: AppState, payload: LoadProtocolInfoAction['payload']): AppState {
    const { chainId, data, isFetching, error } = payload
    return merge(state, {
        [chainId]: {
            api: {
                'protocol-info': { data, isFetching, error }
            }
        }
    }) as AppState
}

function updatePoolsData(state: AppState, payload: UpdatePoolsDataAction['payload']): AppState {
    const { chainId, data, isFetching, error } = payload
    const newState = merge(state, {
        [chainId]: {
            api: {
                pools: { data, isFetching, error }
            }
        }
    }) as AppState
    if (data && !newState[chainId].displayedPools) {
        newState[chainId].displayedPools = data.stakingPools
    }
    return newState
}

function togglePoolCard(state: AppState, payload: TogglePoolCardAction['payload']): AppState {
    const { chainId, poolId } = payload
    const currentOpenPoolCard: number | undefined = state[chainId]?.openPoolCard
    if (currentOpenPoolCard === poolId) {
        return merge(state, {
            [chainId]: {
                openPoolCard: undefined
            }
        }) as AppState
    }
    return merge(state, {
        [chainId]: {
            openPoolCard: poolId
        }
    }) as AppState
}

function displayPools(state: AppState, payload: DisplayPoolsAction['payload']): AppState {
    const { chainId, pools } = payload
    return merge(state, {
        [chainId]: {
            displayedPools: pools
        }
    }) as AppState
}

// Context
export const AppStateContext = createContext([{} as AppState, (action: AppStateAction) => {}])

interface AppStateProviderProps {
    children: ReactNode
}

export function AppStateProvider({ children }: AppStateProviderProps) {
    const [store, dispatch] = useReducer<Reducer<AppState, AppStateAction>>(appStateReducer, {})

    return (
        <AppStateContext.Provider value={[store, dispatch]}>
            {children}
        </AppStateContext.Provider>
    )
}

// Hook
export function useAppStateReducer() {
    return useContext(AppStateContext) as [AppState, React.Dispatch<AppStateAction>]
}
