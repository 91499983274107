import styled, { css } from "styled-components";
import {useEffect, useState} from "react";
import {AppStateActionName, useAppStateReducer} from "../../../AppState";
import {useRawPools} from "../../../hooks/useRawPools";
import {useWeb3ReactPlus} from '../../../Web3ReactPlus/Web3ReactPlusProvider';

function SearchAndFilter() {
    const [_, dispatch] = useAppStateReducer()
    const { chainId } = useWeb3ReactPlus()
    const [searchQuery, setSearchQuery] = useState('')
    const [sortBy, setSortBy] = useState('Default')
    const [sortByDisplay, setSortByDisplay] = useState('Default')

    const rawPools = useRawPools()

    function handleSeachOnChange(e) {
        setSearchQuery(e.target.value)
    }

    function handleSortByChange(e) {
        setSortBy(e.target.value)
        setSortByDisplay(e.target[e.target.selectedIndex].textContent)
        e.target.blur()
    }

    const sortByComparators = {
        Default() {
            return 0
        },
        "APR-asc"(poolA, poolB) {
            return poolA.apr - poolB.apr
        },
        "APR-desc"(poolA, poolB) {
            return poolB.apr - poolA.apr
        },
        "TVL-asc"(poolA, poolB) {
            return poolA.tvlUSD - poolB.tvlUSD
        },
        "TVL-desc"(poolA, poolB) {
            return poolB.tvlUSD - poolA.tvlUSD
        },
    }

    useEffect(() => {
        if (searchQuery === '') {
            const pools = [...rawPools]
                .sort(sortByComparators[sortBy] ? sortByComparators[sortBy] : sortByComparators['Default'])
            dispatch({
                type: AppStateActionName.DisplayPools,
                payload: { chainId, pools }
            })
            return
        }
        const pools = [...rawPools]
            .sort(sortByComparators[sortBy] ? sortByComparators[sortBy] : sortByComparators['Default'])
            .filter(({ searchText }) => {
                const mapped = searchQuery.split(' ')
                  .map((term) => searchText.indexOf(term.toLowerCase()) !== -1)
                return mapped.every((isMatch) => isMatch)

            })
        dispatch({
            type: AppStateActionName.DisplayPools,
            payload: { chainId, pools }
        })
    }, [searchQuery, sortBy, rawPools])

    if (!rawPools.length) {
        return null
    }

    return (
        <Container>
            <Search
                type="search"
                placeholder="Search Pair or Token"
                value={searchQuery}
                onChange={handleSeachOnChange}
            />
            <SelectWrap>
                <Select onChange={handleSortByChange}>
                    <Option value="Default">Default</Option>
                    <Option value="APR-asc">APR ⭡</Option>
                    <Option value="APR-desc">APR ⭣</Option>
                    <Option value="TVL-asc">TVL ⭡</Option>
                    <Option value="TVL-desc">TVL ⭣</Option>
                </Select>
                <SearchValue>Sort by: {sortByDisplay}</SearchValue>
            </SelectWrap>

        </Container>
  );
}

export default SearchAndFilter;

const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin-bottom: 18px;
  margin-top: 35px;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 9px;
`;

const Search = styled.input`
  background-color: #ffffff;
  border: none;
  border-radius: 10px;
  width: 339px;
  height: 42px;
  padding: 8px 14px;
  ::placeholder {
    color: #0f1621;
    font-size: 14px;
    //padding-left: 15px;
  }
  :focus::placeholder {
    color: transparent;
  }
`;

const SelectWrap = styled.div`
  position: relative;
`

const Select = styled.select`
  width: 160px;
  height: 42px;
  background-color: #ffffff;
  border: none;
  border-radius: 10px;
  padding: 6px;
  color: transparent;
`;

const Option = styled.option`
  border: none;
  color: black;
`

const SearchValue = styled.span`
  font-size: 14px;
  position: absolute;
  pointer-events: none;
  left: 0;
  padding: 13px;
  line-height: 1;
`